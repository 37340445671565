.button-primary {
  height: 38px;
  width: 122px;
  border-radius: 4px;
  border: 1px solid #1b8c96;
  font-weight: 500;
  cursor: pointer;
  &:focus {
    outline: none;
  }

  &__transparent {
    background: transparent;
    color: #1b8c96;
  }
  &__original {
    background-color: #1b8c96;
    color: white;
    border-color: #166f77;
    &.disabled {
      color: #bac0cb;
      background-color: #eaedf3;
      border-color: #eaedf3;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
}

.button-primary-wide {
  height: 38px;
  width: 25%;
  border-radius: 4px;
  border: 1px solid #1b8c96;
  font-weight: 500;
  cursor: pointer;
  &:focus {
    outline: none;
  }

  &__transparent {
    background: transparent;
    color: #1b8c96;
  }
  &__original {
    background-color: #1b8c96;
    color: white;
    border-color: #166f77;
    &.disabled {
      color: #bac0cb;
      background-color: #eaedf3;
      border-color: #eaedf3;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
}

.loading {
  width: 16px;
  height: 16px;
  animation: spin 1s infinite;
  margin-right: 10px;
  margin-left: 7.5px;
}
