.container {
  cursor: pointer;
  padding: 20px 25px;
  border-bottom: 1px solid #5b5f72;

  .rowOne {
    display: flex;
    justify-content: space-between;

    .leftContainer {
      display: flex;

      .downloadsPill,
      .viewsPill {
        text-transform: capitalize;
        display: flex;
        position: relative;
        align-items: center;
        background: #2c3041;
        border-radius: 9.5px;
        padding: 4px 15px;

        .icon {
          margin-right: 8px;
          display: flex;
        }

        .counter {
          font-size: 10px;
        }

        .hoverContainer {
          display: none;

          .text {
            padding: 4px 10px;
            background: black;
            position: absolute;
            font-size: 11px;
            color: white;
            bottom: 27px;
            transform: translateX(-50%);
            left: 50%;
          }

          .tip {
            width: 0;
            position: absolute;
            bottom: 25px;
            left: 50%;
            transform: translateX(-50%);
            border-top: 2px solid black;
            border-left: 2px solid transparent;
            border-right: 2px solid transparent;
          }
        }

        &:hover {
          .hoverContainer {
            display: block;
          }
        }
      }

      .downloadsPill {
        margin-right: 12px;
      }
    }

    .rightContainer {
      display: none;
      .threeDotMenu {
        font-size: 10px;
        color: #bac0cb;
        cursor: pointer;
      }
    }
  }

  .label {
    font-weight: 500;
    font-size: 14px;
    margin-top: 20px;
  }

  .userPrompt {
    background-color: #0a7cb8;
    border-radius: 0 10px 10px 10px;
    border: 1px solid #0a7cb8;
    color: #fff;
    cursor: default;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    margin: 12px 0 30px 0;
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }

  .paigeResponse {
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    border-radius: 10px 0 10px 10px;
    border: 1px solid rgba(255, 255, 255, 0.38);
    color: #fff;
    cursor: default;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    margin: 12px 0 30px 0;
    padding: 20px;
    white-space: pre-wrap;
  }

  .rowThree {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .expirationDate {
      color: #bac0cb;
      font-style: italic;
      font-weight: 500;
      font-size: 12px;
    }

    .copyLink {
      display: flex;
      cursor: pointer;

      .text {
        color: #00afbf;
        font-weight: 500;
        font-size: 13px;
        margin-right: 5px;
      }
    }
  }
}

.loading {
  width: 16px;
  height: 16px;
  animation: spin 1s infinite;
  margin-right: 10px;
  margin-left: 7.5px;
}