.container {
  background-color: #393d50;
  color: #fff;
  z-index: 1;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  height: 100vh;

  .dashBoardLinkContainer {
    border-bottom: 1px solid #5b5f72;
  }

  .dashBoardLink {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.5px;
    color: #1cbacb;
    padding: 16px 0px 16px 26px;
    cursor: pointer;
  }

  .header {
    padding: 29px 27px;
    border-bottom: 1px solid #5b5f72;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 24px;
      font-weight: 500;
    }

    .closeButton {
      img {
        cursor: pointer;
      }
    }
  }

  .body {
    flex-grow: 1;
    overflow-y: scroll;
    height: 100%;

    .emptyStateContainer {
      padding: 20px 27px;
      height: 100% !important;
      box-sizing: border-box;

      .emptyState {
        height: 100%;
        border-radius: 4px;
        background: #2c3041;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .text {
          width: 80%;
          font-weight: 500;
          font-size: 13px;
          text-align: center;

          .textOne {
            margin-bottom: 20px;
          }
        }
      }
    }

    .shareLinksAdminContainer {
      margin-top: 30px;
    }
  }

  .createNewShareLinkContainer {
    padding: 0px 20px 30px 20px;
    box-sizing: border-box;
    border-top: 1px solid #5b5f72;

    :global(.button-primary) {
      width: 100%;
    }
  }
}

.editor {
  width: calc(100% - 40px);
  margin-left: 20px;
  padding: 20px 0;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}

.editor :global(.public-DraftEditor-content) {
  overflow: scroll;
  max-height: 50vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (min-width: 1440px) {
  .container {
    width: 400px;
  }
}
